<template>
  <section class="container-fluid mt-4 admin-dashboard">
    <div class="box px-4 py-2">
      <div class="">
        <b-alert v-model="alert.display" :variant="alert.type" class="alert-bar shadow">
          {{ alert.message }}
        </b-alert>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-between">
          <div id="left" class="d-flex w-75 align-items-center">
            <div class="w-50">
              <select class="form-control text-center" v-model="filteredRole">
                <option value="">ทั้งหมด</option>
                <option v-for="role in allRoles" :key="role.id" :value="role.name" v-show="role.name !== 'CMU'">
                  {{ role.name | user_roles }}
                </option>
              </select>
            </div>
            <div class="d-flex w-75 mx-2 align-items-center">
              <label class="text-nowrap">ค้นหา</label>
              <input class="w-100 form-control ml-2" name="search" id="search"
                     placeholder="ค้นหา ชื่อ/อีเมล/จังหวัด ผู้ใช้.."
                     v-model="search">
            </div>
            <div>
              <button class="btn btn-secondary clear-button ml-2 d-flex align-items-center justify-content-center"
                      @click="clearAll()">
                ล้างการค้นหา
              </button>
            </div>
          </div>
          <div id="right" class=" d-flex align-items-center ">
            <button
              class="btn btn-action add-button text-white d-flex flex-column align-items-center justify-content-center position-relative"
              @click="createUser('normal')">
              <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute create-icon" fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z" />
              </svg>
              <small class="mt-1 font-weight-bold">สร้างผู้ใช้</small>
            </button>
            <button
              class="btn btn-action add-button text-white d-flex flex-column align-items-center justify-content-center position-relative"
              @click="createUser('gov_id')"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="position-absolute create-icon" fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z" />
              </svg>
              <small class="mt-1 font-weight-bold">GovID</small>
            </button>
          </div>
        </div>
      </div>
      <table class="table table-hover table-sm mt-2">
        <thead class="bg-gray-100 text-light-ipepp">
        <th>ID</th>
        <th>อีเมล</th>
        <th>ชื่อ</th>
        <th>สิทธ์</th>
        <th>จังหวัด</th>
        <th class="text-center">แก้ไข</th>
        </thead>
        <tbody v-if="ready" class="text-light-ipepp">
        <tr v-for="user in users.data" :key="user.id" v-show="user.role !== 'CMU'">
          <td>{{ user.id }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.role | user_roles }}</td>
          <td>{{ user.province }}</td>
          <td class="d-flex justify-content-center">
            <div class="icon text-red" @click="editUser(user)">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
              </svg>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-if="ready && users.data.length === 0" class="text-center text-light-ipepp mt-4 mb-4">
        ไม่มีบัญชีผู้ใช้...
      </div>
      <div v-if="ready" class="d-flex justify-content-end mr-4">
        <b-pagination-nav
          :link-gen="linkGen"
          :number-of-pages="users.meta.pagination.total_pages"
          use-router>
        </b-pagination-nav>
      </div>
    </div>
    <modals-container
      @userSaved="userSaved"
      @userUpdated="userUpdated"
      @userDeleted="userDeleted"
      @userReset="userReset"
    ></modals-container>
  </section>
</template>

<script>
import UserForm from '@/components/UserForm.vue';

export default {
  name: 'ManageUsers',
  data() {
    return {
      ready: false,
      users: null,
      page: 1,
      selectedPage: null,
      search: '',
      filteredRole: '',
      allRoles: null,
      allProvinces: null,
      alert: {
        display: 0,
        type: '',
        message: ''
      }

    };
  },
  created() {
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    clearAll() {
      this.selectedPage = 1;
      this.search = "";
      this.filteredRole = "";
    },
    loadUsers() {
      let url = '/api/users';
      url = url + '?page=' + this.selectedPage ?? 1;
      url = url + '&role=' + this.filteredRole;
      url = url + '&search=' + this.search;
      window.axios
        .get(url)
        .then((response) => {
          this.users = response.data;
          this.ready = true;
        }).catch((error) => {
        this.ready = false;
      });
      this.prepareData();
    },
    setAlert(message, type = 'success', display = 1) {
      this.alert.display = display;
      this.alert.type = type;
      this.alert.message = message;
    },
    userReset(response) {
      if (response === 'error') {
        this.setAlert('เกิดข้อผิดพลาดระหว่างทำการรีเซตอีเมล', 'danger');
        return;
      }
      if (response === 'success') {
        this.setAlert('ทำการรีเซตอีเมลสำเร็จ กรุณาแจ้งผู้ใช้ตรวจสอบอีเมล',);
        return;
      }
    },
    userDeleted(response) {
      this.$modal.hide('user_form');
      if (response === 'success') {
        this.setAlert('ลบบัญชีผู้ใช้สำเร็จ');
        this.submitSearch();
      }
      if (response === 'error') {
        this.setAlert('เกิดข้อผิดพลาดระหว่างการลบบัญชีผู้ใช้', 'danger');
      }
      this.submitSearch();
    },
    userSaved(response) {
      if (response.status === 'error') {
        this.setAlert('เกิดข้อผิดพลาดระหว่างการสร้างบัญชีผู้ใช้', 'danger');
        return;
      }
      if (response.user_type === 'normal') {
        this.setAlert('สร้างบัญชีผู้ใช้ใหม่สำเร็จ กรุณาแจ้งผู้ใช้ตรวจสอบและยืนยันอีเมล');
        this.submitSearch();
      }
      if (response.user_type === 'gov_id') {
        this.setAlert('เพิ่ม Gov ID สำเร็จ');
        this.submitSearch();
      }
    },
    userUpdated(response) {
      if (response.status === 'error') {
        this.setAlert('เกิดข้อผิดพลาดระหว่างการแก้ไขบัญชีผู้ใช้งาน', 'danger');
        return;
      }
      if (response.status === 'success') {
        this.setAlert('แก้ไขบัญชีผู้ใช้งาน สำเร็จ', 'success');
        this.submitSearch();
      }
    },
    linkGen(pageNum) {
      this.page = pageNum;
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    createUser(type) {
      this.$modal.show(
        UserForm,
        {
          name: 'user_form',
          mode: 'create',
          userType: type,
          allRoles: this.allRoles,
          allProvinces: this.allProvinces,
          user: {}
        },
        {
          name: 'user_form',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    editUser(user) {
      this.$modal.show(
        UserForm,
        {
          name: 'user_form',
          mode: 'edit',
          allRoles: this.allRoles,
          allProvinces: this.allProvinces,
          user: user
        },
        {
          name: 'user_form',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '65%',
          transition: 'fade',
        }
      );
    },
    prepareData() {
      window.axios
        .get(
          '/api/prepare-roles-and-provinces'
        )
        .then((response) => {
          this.allRoles = response.data.roles;
          this.allProvinces = response.data.provinces;
        }).catch((error) => {
        console.log('--------error---------');
        console.log(error);
        console.log('-------error----------');
        this.allRoles = null;
      });
    },
    submitSearch() {
      let url = '/api/users';
      url = url + '?page=' + this.selectedPage ?? 1;
      url = url + '&role=' + this.filteredRole;
      url = url + '&search=' + this.search;
      window.axios
        .get(url)
        .then((response) => {
          this.users = response.data;
          this.ready = true;
        }).catch((error) => {
        this.ready = false;
      });
    }

  },
  watch: {
    page() {
      this.page = this.$route.query.page;
      this.selectedPage = this.$route.query.page ?? '1';
    },
    selectedPage() {
      window.axios
        .get(
          '/api/users?page=' + this.selectedPage
        )
        .then((response) => {
          this.users = response.data;
          this.ready = true;
        }).catch((error) => {
        this.ready = false;
      });
    },
    filteredRole() {
      this.submitSearch()
    },
    search() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.submitSearch();
      }, 500);
    }
  }
};
</script>

<style lang="scss">
label {
  margin-bottom: 0 !important;
}

.page-item.active .page-link {
  background-color: #F3F4F6 !important;
  border-color: #E5E7EB !important;
}

.page-link {
  color: #4B5563 !important;
}

.add-button {
  min-width: 100px;
  max-width: 100px;
  height: 55px;
  margin-left: 10px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.clear-button {
  min-width: 150px;
  max-width: 150px;
  height: 55px;
  margin-left: 10px;
}

.table-orange {
  background-color: rgb(248, 97, 60);
}

.icon {
  width: 24px;
  cursor: pointer;
}

.create-icon {
  width: 16px;
  color: white;
  top: 5px;
  right: 5px;
}

.alert-bar {
  position: fixed;
  top: 30%;
  left: 50%;
  width: 80%;
  z-index: 100;
  transform: translate(-50%, -50%);
  animation-name: alert-bar-display;
  animation-duration: 2s;
  animation-timing-function: ease-out;

}

@keyframes alert-bar-display {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
