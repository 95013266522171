<template>
  <div class="box">
    <div class="box-header d-flex justify-content-between">
      <div class="d-flex">
        <h4>
          <div v-if="userType==='normal'">
            <strong v-if="mode==='create'">สร้างบัญชีผู้ใช้งานใหม่</strong>
            <strong v-if="mode==='edit'">แก้ไขบัญชีผู้ใช้งาน</strong>
          </div>
          <div v-if="userType==='gov_id'">
            <strong v-if="mode==='create'">เพิ่ม Gov ID</strong>
            <strong v-if="mode==='edit'">แก้ไข Gov ID</strong>
          </div>
        </h4>
        <span
          class="pointer text-blue ml-3 font-weight-normal text-nowrap"
          @click="showGuidingModal()"
        >
          <u>ดูคำแนะนำ</u>
        </span>
      </div>
      <div class="text-red icon" v-if="mode==='edit'" @click="deleteConfirm()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
        </svg>
      </div>

    </div>
    <div class="box-body">

      <form
        @submit.prevent="submit"
        v-promise-btn="{ action: 'submit' }"
        class="container"
      >
        <input type="hidden" id="form_user_type" :value="userEdit.userType" />
        <div class="row mb-3">
          <div class="col d-flex">
            <div class="col-3">
              <label v-if="userType==='normal'" class="mr-4 mt-2">อีเมล</label>
              <label v-if="userType==='gov_id'" class="mr-4 mt-2">Gov ID อีเมล</label>
            </div>
            <div class="col-8">
              <input
                v-if="this.mode==='create'"
                class="form-control flex-fill"
                type="email"
                id="form_email"
                v-model="userEdit.email"
                required
              />
              <input
                v-if="this.mode==='edit'"
                class="form-control flex-fill"
                type="email"
                id="form_email"
                v-model="userEdit.email"
                readonly
              />
              <div class="text-right">
                <span v-if="emailError" class="text-danger">อีเมลนี้ถูกใช้งานแล้ว</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <div class="col-3">
              <label for="name" class="mr-4">ชื่อ</label>
            </div>
            <div class="col-8">
              <input
                class="form-control flex-fill"
                type="text"
                id="form_name"
                v-model="userEdit.name"
                required
              />
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex align-items-center">
            <div class="col-3">
              <label for="role" class="mr-4">สิทธ์</label>
            </div>
            <div class="col-8">
              <select @change="updateFormError" class="form-control" v-model="userEdit.role" required>
                <option v-for="role in allRoles" :key="role.id" :value="role.name" v-show="canCreateRole(role.name)">
                  {{ role.name | user_roles }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row mb-3" v-show="userEdit.role ==='PEnO'">
          <div class="col d-flex">
            <div class="col-3">
              <label for="province" class="mr-4 mt-2">จังหวัด</label>
            </div>
            <div class="col-8">
              <select class="form-control" v-model="userEdit.province">
                <option value="">กรุณาเลือกจังหวัด</option>
                <option value="">ไม่สังกัดจังหวัด</option>
                <option v-for="province in allProvinces" :key="province.id" :value="province.id">
                  {{ province.name_th }}
                </option>
              </select>
              <div class="text-right">
                <p v-if="hasError" class="text-danger">กรุณากรอกจังหวัด</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex">
            <div class="col-3"></div>
            <div class="col-8 d-flex justify-content-between">
              <div v-if="mode==='create'">
                <div></div>
              </div>
              <div v-if="mode==='edit' && userType==='normal'">
                <div v-if="resettingEmail==false">
                  <button @click="resetEmail()" class="btn btn-outline-red d-flex align-items-center" type="button">
                    รีเซตอีเมล
                  </button>
                </div>
                <div v-if="resettingEmail==true">
                  <button class="btn btn-outline-red d-flex align-items-center" type="button" disabled>
                    กำลังรีเซตอีเมล
                    <div class="ml-1 spinner-border text-light spinner-border-sm spinner-grow-sm" role="status"></div>
                  </button>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <button class="btn btn-action" type="submit">
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-show="requestError" class="row mb-3">
          <div class="col d-flex align-items-center">
            <div class="col-3">
            </div>
            <div class="col-8 text-right">
              <span class="text-danger">
                เกิดข้อผิดพลาดบางประการ
              </span>
            </div>
          </div>
        </div>
        <div
          class="
            d-flex
            flex-row-reverse
            align-items-center
            justify-content-between
          "
        >
        </div>
      </form>
    </div>
    <b-modal id="confirm-delete" hide-footer v-model="deleteModal.show">
      <template #modal-title>
        <span class="text-red modal-header-text">
          ยืนยันการลบบัญชีผู้ใช้งาน
        </span>
      </template>
      <div class="text-center">
        <span class="modal-body text-light-ipepp">
          ท่านต้องการที่จะลบผู้ใช้งานชื่อ  {{ user.name }}  ออกจากระบบใช่หรือไม่?
        </span>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-secondary mx-1 btn-font" @click="deleteModal.show = false">
          ยกเลิก
        </button>
        <button class="btn btn-action mx-1 btn-font" @click="deleteUser()">
          ลบข้อมูล
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Form from '@/modules/form/form';
import {required} from 'vuelidate/lib/validators';
import moment from 'moment-timezone';
import {range} from 'lodash';
import GuidingModal from '@/components/GuidingModal.vue';
import GuidingUser from '@/components/guiding/GuidingUser.vue';

export default {
  name: 'user-form',
  props: {
    name: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'create',
      validator: s => ['create', 'edit'].includes(s)
    },
    userType: {
      type: String,
      default: 'normal',
      validator: () => ['normal', 'gov_id']
    },
    user: {
      type: Object,
      required: true,
    },
    allRoles: {
      type: Array,
      required: true,
    },
    allProvinces: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      userEdit: null,
      formError: [],
      deleteModal: {
        show: false,
      },
      resettingEmail: false
    };
  },
  computed: {
    requestError() {
      let hasError = false;
      if (this.formError.length > 0) {
        hasError = this.formError.find(error => {
          return error == 'backend_error'
        });
      }
      return hasError;
    },
    isEdit() {
      return this.mode == 'edit';
    },
    isCreate() {
      return this.mode == 'create';
    },
    hasError() {
      let checked = this.formError.find((error) => {
        return error == 'province';
      });
      return checked;
    },
    emailError() {
      if (this.formError.length > 0) {
        return this.formError.find(error => {
          return error.email != undefined
        })
      }
      return false;
    },
  },
  created() {
    if (this.isEdit) {
      this.userEdit = new Form({
        data: () => {
          return {
            email: this.user.email,
            name: this.user.name,
            role: this.user.role,
            province: this.user.province_id,
            user_type: this.userType,
          };
        },
        validations: {},
      });
    } else {
      // create
      this.userEdit = new Form({
        data: () => {
          return {
            email: '',
            name: '',
            province: '',
            role: '',
            user_type: this.userType
          };
        },
        validations: {
          email: {required},
          name: {required},
          province: {},
          role: {required}
        },
      });
    }
  },
  methods: {
    showGuidingModal() {
      this.$modal.show(
        GuidingModal,
        {
          name: 'guiding_modal',
          title: '',
          component: GuidingUser,
        },
        {
          name: 'guiding_modal',
          pivotY: 0.2,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '50%',
          transition: 'fade',
        }
      );
    },
    resetEmail() {
      this.resettingEmail = true;
      let url = `/api/users/${this.user.id}/reset`;
      axios.post(url, {}).then((response) => {
        this.$modal.hide(this.name);
        if (response.data === 'success') {
          this.$emit('userReset', response.data);
        }
        this.resettingEmail = false;
      }).catch((error) => {
        console.log('--------error---------');
        console.log(error);
        console.log('-------error----------');
      });
    },
    deleteConfirm() {
      this.deleteModal.show = true;
    },
    deleteUser() {
      let url = `/api/users/${this.user.id}`;
      axios.delete(url, {}).then((response) => {
        this.deleteModal.show = false;
        if (response.data === 'success') {
          this.$emit('userDeleted', response.data);
        }
      }).catch((error) => {
        console.log('--------error---------');
        console.log(error);
        console.log('-------error----------');
      });
    },
    updateFormError() {
      if (this.userEdit.role != 'PEnO') this.formError = [];
    },
    checkForm() {
      if (this.userEdit.role === 'PEnO' && this.userEdit.province === '') {
        return false;
      }
      if (this.userEdit.role != 'PEnO') {
        this.userEdit.province = null;
      }
      return true;
    },
    submit() {
      let submitReady = this.checkForm();
      if (!submitReady) {
        this.formError.push(['province']);
        return;
      }
      let url = `/api/users`;
      let requestType = 'post';
      if (this.mode == 'edit') {
        url = `/api/users/${this.user.id}`;
        requestType = 'patch';
      }
      return this.userEdit
        .submit(requestType, url)
        .then((response) => {
          this.$modal.hide(this.name);
          let emitResponse = {
            user_type: this.userType,
            status: response
          };
          if (this.isCreate) {
            this.$emit('userSaved', emitResponse);
          } else {
            this.$emit('userUpdated', emitResponse);
          }
        })
        .catch((error) => {
          if (error.response.data.errors != undefined) {
            this.formError.push(error.response.data.errors);
          } else {
            this.formError.push('backend_error');
          }
          console.log('-----------------');
          console.log(this.formError.length);
          console.log('-----------------');
        });
    },
    canCreateRole(role) {
      return role !== 'superadmin' && role !== 'CMU';
    },
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
}

.btn {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-font {
  font-size: 20pt;
  font-family: "THSarabunNew", "Avenir", Helvetica, Arial, sans-serif
}

.modal-header-text {
  font-size: 28pt;
  font-family: "THSarabunNew", "Avenir", Helvetica, Arial, sans-serif
}

.modal-body {
  font-size: 20pt;
  font-family: "THSarabunNew", "Avenir", Helvetica, Arial, sans-serif;
}
</style>
